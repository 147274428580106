import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Container from '@components/Container'
import Hero from '@components/Hero'
import PostList from '@components/PostList'
import Footer from '@components/Footer'
import * as S from '@styles/components/Archive'
import SEO from '@components/partials/SEO'
import { metaData } from '@fragments/meta'

export default class Category extends Component {
  // TODO: Inherit flyout usage from main archive
  constructor(props) {
    super(props)
    this.useFlyout = false
  }

  render() {
    const { data, pageContext } = this.props
    const {
      allWordpressPost: { edges: posts },
      allWordpressCategory: { edges: categories },
      wordpressPage: page,
      wordpressCategory: { yoast_meta: meta, ...category },
    } = data

    page.acf.hero.type = 'basic'
    page.acf.hero.heading = category.name

    return (
      <Container page={page}>
        <SEO {...meta} path={category.path} />
        {page.acf.hero && <Hero {...page.acf.hero} {...page} />}
        <main>
          <S.PageWrapper>
            <S.Archive>
              <PostList
                type="post"
                posts={posts}
                categories={categories}
                useFlyout={this.useFlyout}
              />
            </S.Archive>
          </S.PageWrapper>
        </main>
        <Footer />
      </Container>
    )
  }
}

export const pageQuery = graphql`
  query CategoryPage($slug: String!) {
    allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
    wordpressCategory(slug: { eq: $slug }) {
      name
      path
      yoast_meta {
        ...metaData
      }
    }
    allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          id
          name
          slug
          path
        }
      }
    }
    wordpressPage(slug: { eq: "blog" }) {
      path
      acf {
        hero {
          ...HeroFragment
        }
      }
      yoast_meta {
        ...metaData
      }
    }
  }
`
